<template>
  <div class="lqip-image">
    <img ref="lqip" :src="srcLqip" class="lqip-image__lqip" />
    <picture>
      <source
        v-for="(image, i) in images"
        :key="`$lqip-img-src-${i}`"
        :srcset="image"
        :media="imagesMedia[i]"
      />
      <NuxtImg v-if="inView" :src="imageDefault" class="lqip-image__image" />
    </picture>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{ src: string; width: number | string; height: number | string }>()

const { imageDefault, images, imagesMedia } = useImageSourceset(
  props.src,
  Number(props.width),
  Number(props.height),
  false,
  false
)

const lqip = ref<HTMLImageElement | null>(null)
const srcLqip = props.src + `?q=1&fm=pjpg&w=${props.width}hw=${props.height}`

useHead({
  link: [
    {
      rel: 'preload',
      href: srcLqip,
      as: 'image',
      fetchpriority: 'high'
    }
  ]
})

useHead({
  link: [
    {
      rel: 'preload',
      href: '/js/lqip.js',
      as: 'script',
      fetchpriority: 'high'
    }
  ]
})

const inView = ref(false)
const observer = ref<IntersectionObserver>()

onMounted(() => {
  if (!lqip || !lqip.value) return
  observer.value = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          inView.value = true
          observer.unobserve(entry.target)
        }
      })
    },
    {
      root: null,
      rootMargin: '-66px 0px 0px 0px',
      threshold: 0
    }
  )
  observer.value.observe(lqip.value)
})

onUnmounted(() => {
  observer?.value?.disconnect()
})
</script>
